import logo from './logo.svg';
import './App.css';
import Header from './component/Header/Header';
import 'remixicon/fonts/remixicon.css'
import { useEffect, useState } from "react";
function App() {

  
  return (
    <div className="App">
      <Header />
     
      <div className='test'>
<h1>Start Your Fitness</h1>
<p>World is committed to making participation in the harassment. experience for everyone, regardless of level of experience.</p>
<h2>Starting From $39/Mp</h2>
<div className='button'>
<button className='bt1'>Get Started <i class="ri-arrow-right-line"></i></button>
<button className='bt2'>Live Demo</button></div>
      </div>
    </div>
  );
}

export default App;
